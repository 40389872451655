import "./gallery.scss";

import React from "react";
import {InfoCircledIcon} from "@radix-ui/react-icons";

const cards = [
    {
        title: "Avalonia",
        desc: "International Software Agency. Currently collaborating with 7 international clients. Founded in 2022.",
        tags: ["Marketing-app designed and built with VueJS", "Vuetify", "SCSS", "Three.js"],
        imageUrl: "/assets/img/dajospatryk_avalonia.webp",
        onClickUrl: "https://avalonia.space",
        backlinkUrl: "https://www.google.com/url?q=https://www.pinpop.io"
    },
    {
        title: "PinPop",
        desc: "SaaS. Messenger and call app capable of online and offline communication. End-to-end encrypted and partly de-centralized.",
        tags: ["App designed and built with Swift", "C# .NET9", "Nuxt3", "Stripe", "PostgreSQL", "Android Jetpack Compose", "Docker", "Nginx"],
        imageUrl: "/assets/img/dajospatryk_pinpop.webp",
        onClickUrl: "https://pinpop.app",
        backlinkUrl: "https://intranet.canadabusiness.ca/?URL=https://www.pinpop.app"
    },
    {
        title: "Immobilien Makler Akademie",
        desc: "Academy & SaaS. Biggest academy for real-estate agents in the german-speaking region. Contributing to the maintenance and expansion since 2023.",
        tags: ["Made by Fify in 2021 with Symfony", "HotWired", "Doctrine", "Redis", "SQL", "Javascript", "PhP"],
        imageUrl: "/assets/img/dajospatryk_immobilien_makler_akademie.webp",
        onClickUrl: "https://immobilien-makler-akademie.com",
        backlinkUrl: "https://wikimapia.org/external_link?url=https://www.pinpop.life"
    },
    {
        title: "ESO Stats",
        desc: "SaaS. Self sustaining game-wiki app. Gained 100 subscribers in the first month of its beta release. Currently having ~3000 registered users. Founded in 2023.",
        tags: ["App designed and built with Vue2", "Vuetify", "NodeJS", "MongoDB", "Auth0", "OpenAI", "Javascript"],
        imageUrl: "/assets/img/dajospatryk_esostats.webp",
        onClickUrl: "https://eso-stats.pro/landing",
        backlinkUrl: "https://www.bing.com/news/apiclick.aspx?ref=FexRss&aid=&url=https://www.motorcyclesapp.com"
    }
    /*{
        title: "Prestige Cars",
        desc: "SaaS. International luxury car rental services. Contributing to the maintenance and expansion since 2024. Designed and built 3D features.",
        tags: ["Made by Modernice with Nuxt3", "Vue", "Golang / Goes", "Tailwind", "Shadcn", "Three.js"],
        imageUrl: "/assets/img/dajospatryk_prestige_cars.webp",
        onClickUrl: "https://prestige.cars"
    }*/
]

const renderCard = ({title, desc, tags, imageUrl, onClickUrl, backlinkUrl}) => (

    <a key={title} href={onClickUrl} target="_blank" className="no-deco">
        <div className="gallery-card">
            <div
                className="image md:h-64 h-48"
                style={{
                    backgroundImage: `url('${imageUrl}')`,
                    backgroundPosition: "center right",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }}
            />
            <h3 className="font-eunoia md:text-6xl text-4xl mt-6 mb-2">{title}</h3>
            <p className="text-sm border-b border-util-50 pb-2 mb-2">{desc}</p>
            <p className="text-sm">
                {tags.map((tag, index) => (
                    <span key={index}>
                        {tag}{index < tags.length - 1 ? ' - ' : ''}
                    </span>
                ))}
                <a
                    className="cursor-default mt-2"
                    href={backlinkUrl}
                    title="More information"
                    aria-label="More information about the topic"
                    rel="noopener noreferrer"
                >
                    <InfoCircledIcon/>
                    <span className="sr-only">More information</span>
                </a>
            </p>
        </div>
    </a>
)

function Component() {
    return (
        <div id="gallery" className="gallery-container">
            <div className="padding-box">
                <h2 className="text--l">Running Projects</h2>
                <div className="spacer"/>
            </div>

            <div className="gallery md:px-8 px-4">
                <div className="grid 6xl:grid-cols-6 4xl:grid-cols-4 2xl:grid-cols-3 xl:grid-cols-2 grid-cols-1 gap-4">
                    {cards.map(card => (
                        renderCard(card)
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Component;